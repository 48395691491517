import apiEndpoints from '@/models/common/api-endpoints';
import { mapGetters } from 'vuex';

export default {
  name: 'global-admin-users',
  components: {
  },
  data () {
    return {
      magicGUID: '00000000-0000-0000-0000-000000000000',
      card_title: 'Global Users',
      editTitle: 'Edit User',
      newTitle: 'Invite User',
      edit_dialog: false,
      email_valid: false,
      new_dialog: false,
      newUser: {
        emailAddress: 'None',
        permissions: []
      },
      editedItem: {},
      originalItem: {},
      editedIndex: undefined,
      search: undefined,
      loadingUserTable: true,
      headers: [
        {
          text: 'Email Address',
          align: 'start',
          sortable: false,
          value: 'emailAddress'
        },
        { text: 'Permissions', value: 'permissions' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      items: undefined,
      new_processing: false,
      edit_processing: false,
      availablePermissions: [
        'GlobalAdmin'
      ],
      rules: {
        required: value => !!value || 'A value is required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid email address entered';
        }
      }

    };
  },
  methods: {
    defaultNewUser () {
      const newUser = {
        emailAddress: '',
        permissions: []
      };

      newUser.permissions.push(this.availablePermissions[0]);

      return newUser;
    },
    inviteNewUser () {
      this.newUser = this.defaultNewUser();

      this.new_dialog = true;
    },
    editItem (item) {
      // Save an in-progress version of the item to be edited
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      // persist the original version of the item
      this.originalItem = Object.assign({}, item);

      // Show the dialog
      this.edit_dialog = true;
    },
    hasPermission (permission) {
      if (this.editedItem.permissions.includes(permission)) {
        return true;
      }
      return false;
    },
    retrieveUsers: async function () {
      // set the loading state and clear the current items data
      this.loadingUserTable = true;
      this.items = undefined;

      // retrieve the users
      const response = await apiEndpoints.getPermissionsForClinic(this.magicGUID);

      if (response.result) {
        // test for sysAdmin
        if (!this.$store.getters.isSysAdmin) {
          // filter out own object if present
          this.items = response.data.filter((item) => {
            return !(item.userId === this.$store.getters.idToken);
          });
        } else {
          // if sysAdmin, allow to see self in the table
          this.items = response.data;
        }
      }

      // Set the user table loading state to false
      this.loadingUserTable = false;
    },
    save: async function () {
      // do some validation
      if (this.editedItem.permissions.length < 1) {
        if (!confirm('Revoking all available permissions will cause you to lose visibility of this user. Would you like to continue?')) {
          this.closeEditDialog();
          return;
        }
      }

      // set the processing state
      this.edit_processing = true;

      // console.log('persisting...')
      // persist the changes
      let element;
      for (element of this.availablePermissions) {
        // if the permission is present, but didn't used to be
        if (this.editedItem.permissions.includes(element) && !this.originalItem.permissions.includes(element)) {
          // Add the permission
          await apiEndpoints.addPermissionForClinic(this.editedItem.userId, element, this.magicGUID);
        }

        // if the permission is not present, but used to be
        if (!this.editedItem.permissions.includes(element) && this.originalItem.permissions.includes(element)) {
          // Remove the permission
          await apiEndpoints.removePermissionForClinic(this.editedItem.userId, element, this.magicGUID);
        }
      }

      // refresh the user list
      this.retrieveUsers();

      // // close the dialog
      this.closeEditDialog();

      // set the processing state
      this.edit_processing = false;
    },
    closeEditDialog () {
      this.edit_dialog = false;
    },
    closeNewDialog () {
      // Close the dialog
      this.new_dialog = false;
    },
    sendNew: async function () {
      // set processing state
      this.new_processing = true;

      // send the invitation
      const response = await apiEndpoints.inviteUserForClinic(this.newUser.emailAddress, this.newUser.permissions[0], this.magicGUID);

      if (response.result) {
        // refresh the user list
        await this.retrieveUsers();
      }

      // Close the dialog
      this.closeNewDialog();

      // set processing state
      this.new_processing = false;
    }
  },
  mounted () {
    this.retrieveUsers();
  },
  computed: {
    ...mapGetters({
      currentClinic: 'currentClinic',
      allClinics: 'allClinics'
    })
  },
  watch: {
    currentClinic: function (newVal, oldVal) {
      this.retrieveUsers();
    },
    allClinics: function (newVal, oldVal) {
      this.retrieveUsers();
    }
  }
};